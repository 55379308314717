body { background-color: #ffffff; }
body { color: var(--clr-10625); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-10625);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 28px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-10625);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-10625);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-10625);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 24px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-10625);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

}
h6 {
color: var(--clr-10625);
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Raleway';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 23px;

}
@media #{$large-up} {
font-size: 25px;

}
}
.me-Quote .quote-author {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Raleway';
font-weight: 300;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
summary {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Raleway';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Raleway';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 30px;

@media #{$large-up} {
font-size: 54px;

}
}
body {
font-family: 'Raleway';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-10626);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-10625);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-10625);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-10625);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-10627);
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-10627);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-10627);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Shade 2:6 */
.MES6 {
background-color: var(--clr-10628);
 }
/* Shade 2:7 */
.MES7 {
background-color: var(--clr-10628);
 }
.MES7 {
background-color: var(--clr-10628);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-10629);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-10629);
 }
.MES9 {
background-color: var(--clr-10629);
 }
/* Shade 1:10 */
.MES10 {
background-color: var(--clr-10626);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-10626);
 }
.MES11 {
background-color: var(--clr-10626);
 }
/* Slider Call to action:12 */
.MES12 {
color: #ffffff;
 }
.MES12 {
color: #ffffff;
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: #ffffff;
 }
h1.MEC12 { @media #{$large-up} { font-size: 56px; }; }
h2.MEC12 { @media #{$large-up} { font-size: 49px; }; }
h3.MEC12 { @media #{$large-up} { font-size: 42px; }; }
h4.MEC12 { @media #{$large-up} { font-size: 35px; }; }
h5.MEC12 { @media #{$large-up} { font-size: 28px; }; }
h6.MEC12 { @media #{$large-up} { font-size: 25.2px; }; }
 }
cite.MEC12{
color: #ffffff;
}
/* Arrows:13 */
.MES13 {
& .slider-arrow {background-color: 1;
color: var(--clr-10630);
font-size: 70px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
} }
/* Image roll over panel:14 */
.MES14 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/23986/10');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:450px;
@media #{$medium-up} {
min-height: 450px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES14 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/23986/10');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:450px;
@media #{$medium-up} {
min-height: 450px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
cite.MEC14{
color: #ffffff;
}
/* roll over 2:15 */
.MES15 {
background-color: var(--clr-10631);
color: #ffffff;
 }
.MES15 {
background-color: var(--clr-10631);
color: #ffffff;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
cite.MEC15{
color: #ffffff;
}
/* Secondary:16 */
.MES16 {
background-color: var(--clr-10627);
&:hover {background-color: var(--clr-10625);}
color: #ffffff;
font-size: 16px;
@media #{$medium-up} {
font-size: 18px;
};
border-radius: 50px;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Popup Close Button:17 */
.MES17 {
background-color: transparent;
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: var(--clr-10628);}
font-size: 32px;
@media #{$medium-up} {
font-size: 36px;
};
@media #{$large-up} {
font-size: 54px;
};
padding: 0 20px;

 }
/* Hambuger menu:18 */
.MES18 {
 }
.MES18 {
 }
a.MEC18 { color: #ffffff;
&:hover { color: var(--clr-10628);}
 }
/* Credit footer:19 */
.MES19 {
background-color: var(--clr-10632);
color: #ffffff;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 11.2px;
};
padding: 0 15px;

@media #{$large-up} {
padding: 0 50px;

}
 }
.MES19 {
background-color: var(--clr-10632);
color: #ffffff;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 11.2px;
};
padding: 0 15px;

@media #{$large-up} {
padding: 0 50px;

}
 }
a.MEC19 { color: #ffffff;
&:hover { color: var(--clr-10628);}
 }
cite.MEC19{
color: #ffffff;
font-size: 12.6px;
@media #{$medium-up} {
font-size: 11.2px;
};
}
/* Footer info:20 */
.MES20 {
background-color: var(--clr-10625);
color: #ffffff;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 15px;

@media #{$large-up} {
padding: 20px 50px;

}
 }
.MES20 {
background-color: var(--clr-10625);
color: #ffffff;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
padding: 15px;

@media #{$large-up} {
padding: 20px 50px;

}
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
a.MEC20 { color: #ffffff;
&:hover { color: var(--clr-10628);}
 }
cite.MEC20{
color: #ffffff;
font-size: 16.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Popup Menu:21 */
.MES21 {
background-color: var(--clr-10633);
color: #ffffff;
font-size: 25.2px;
@media #{$medium-up} {
font-size: 32px;
};
@media #{$large-up} {
font-size: 48px;
};
 }
.MES21 {
background-color: var(--clr-10633);
color: #ffffff;
font-size: 25.2px;
@media #{$medium-up} {
font-size: 32px;
};
@media #{$large-up} {
font-size: 48px;
};
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: #ffffff;
 }
 }
a.MEC21 { color: #ffffff;
&:hover { color: var(--clr-10628);}
 }
cite.MEC21{
color: #ffffff;
font-size: 25.2px;
@media #{$medium-up} {
font-size: 32px;
};
@media #{$large-up} {
font-size: 48px;
};
}
/* Dark:22 */
.MES22 {
background-color: var(--clr-10632);
 }
/* Dark:23 */
.MES23 {
background-color: var(--clr-10632);
 }
.MES23 {
background-color: var(--clr-10632);
 }
/* Background image:24 */
.MES24 {
background-color: var(--clr-10625);
& > .underlay, & > .inner-overlay { opacity: 0.7 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/23832/307');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:550px;
@media #{$medium-up} {
min-height: 600px;};
@media #{$large-up} {
min-height: 500px;};
padding: 15px;

@media #{$large-up} {
padding: 20px 50px;

}
 }
.MES24 {
background-color: var(--clr-10625);
& > .underlay, & > .inner-overlay { opacity: 0.7 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/23832/307');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:550px;
@media #{$medium-up} {
min-height: 600px;};
@media #{$large-up} {
min-height: 500px;};
padding: 15px;

@media #{$large-up} {
padding: 20px 50px;

}
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: #ffffff;
 }
 }
cite.MEC24{
color: #ffffff;
}
/* Menu Text Popup:25 */
.MES25 {
color: #ffffff;
font-size: 36px;
@media #{$medium-up} {
font-size: 32px;
};
@media #{$large-up} {
font-size: 48px;
};
 }
.MES25 {
color: #ffffff;
font-size: 36px;
@media #{$medium-up} {
font-size: 32px;
};
@media #{$large-up} {
font-size: 48px;
};
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: #ffffff;
 }
&:hover { h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: var(--clr-10628);} }
 }
a.MEC25 { color: #ffffff;
&:hover { color: var(--clr-10628);}
 }
cite.MEC25{
color: #ffffff;
font-size: 36px;
@media #{$medium-up} {
font-size: 32px;
};
@media #{$large-up} {
font-size: 48px;
};
}
/* Background image bottom:26 */
.MES26 {
background-color: var(--clr-10625);
color: #ffffff;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES26 {
background-color: var(--clr-10625);
color: #ffffff;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: #ffffff;
 }
 }
cite.MEC26{
color: #ffffff;
}
/* Light:27 */
.MES27 {
background-color: var(--clr-10634);
 }
/* Light:28 */
.MES28 {
background-color: var(--clr-10634);
 }
.MES28 {
background-color: var(--clr-10634);
 }
/* Shade 3:29 */
.MES29 {
background-color: var(--clr-10635);
 }
/* Shade 3:30 */
.MES30 {
background-color: var(--clr-10635);
 }
.MES30 {
background-color: var(--clr-10635);
 }
/* Popup Menu:31 */
nav.me-Menu.MES31 {
& .menu-item.MEC31, & .menu-item.MEC31 > div.MEC31{ & > a.MEC31{color: #ffffff;
font-size: 27px;
@media #{$medium-up} {
font-size: 36px;
}@media #{$large-up} {
font-size: 54px;
}text-transform: capitalize;
}
 &:hover > a.MEC31{color: var(--clr-10628);
}
 }
&.horizontal > .menu-item.MEC31 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC31 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC31 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 1;
border-style: solid;
 }
&.horizontal > .menu-item.MEC31 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

@media #{$large-up} {
& > .menu-item > a{padding: 10px 100px;}

}
& .sub-menu .menu-item a{padding: 15px;}



&.horizontal .menu-item.MEC31:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid transparent;}}
&.vertical .menu-item.MEC31:hover {& > .pointer-wrap > .pointer{border-right: 10px solid transparent;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC31{background-color: transparent; &:hover {background-color: 1;}
}
& .menu-item.MEC31, & .menu-item.MEC31 > div.MEC31{ & > a.MEC31{color: #ffffff;
font-size: 18px;
@media #{$medium-up} {
font-size: 36px;
}@media #{$large-up} {
font-size: 54px;
}}
 &:hover > a.MEC31{color: 4;
}
 }

}
}
 }
/* Menu top:32 */
nav.me-Menu.MES32 {
& .menu-item.MEC32, & .menu-item.MEC32 > div.MEC32{ & > a.MEC32{color: #ffffff;
font-size: 18px;
@media #{$large-up} {
font-size: 18.9px;
}text-transform: capitalize;
}
 &:hover > a.MEC32{color: var(--clr-10628);
}
 }
.menu-item.MEC32 {  }
&.horizontal > .menu-item.MEC32 { border:0;
border-color: var(--clr-10632);
border-style: dotted;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC32 { border:0;
border-color: var(--clr-10632);
border-style: dotted;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC32 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 1;
border-style: solid;
 }
&.horizontal > .menu-item.MEC32 .sub-menu { border:1px;
 & > .top-spacer, & > .bottom-spacer {background-color: var(--clr-10633);}
 & > .top-spacer {height: 10px;}
 & > .bottom-spacer {height: 10px;}
 }
&.horizontal .menu-item.MEC32:hover { & > .sub-menu {transform: none !important;
  text-align: left;
 left: 0;
}}
& > .menu-item > a{padding: 10px;}

& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC32:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-10633-flat);}}
&.vertical .menu-item.MEC32:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-10633-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC32{background-color: var(--clr-10633);}
& .menu-item.MEC32, & .menu-item.MEC32 > div.MEC32{ & > a.MEC32{color: #ffffff;
font-size: 18px;
}
 &:hover > a.MEC32{color: 3;
}
 }

}
}
 }
/* Header panel :33 */
.MES33 {
color: #ffffff;
 }
.MES33 {
color: #ffffff;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
&:hover { h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;} }
 }
a.MEC33 { color: #ffffff;
&:hover { color: var(--clr-10628);}
 }
cite.MEC33{
color: #ffffff;
}
/* Background testimonial block:34 */
.MES34 {
background-color: var(--clr-10625);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/24187/80');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES34 {
background-color: var(--clr-10625);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/24187/80');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:400px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: #ffffff;
 }
 }
cite.MEC34{
color: #ffffff;
}
/* Suppliers carousel:35 */
.MES35 {
min-height:200px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 200px;};
padding: 15px;

@media #{$large-up} {
padding: 30px;

}
 }
.MES35 {
min-height:200px;
@media #{$medium-up} {
min-height: 200px;};
@media #{$large-up} {
min-height: 200px;};
padding: 15px;

@media #{$large-up} {
padding: 30px;

}
 }
/* folio mask:36 */
.MES36 {
background-color: transparent;
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px 50px;

}
 }
.MES36 {
background-color: transparent;
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px 50px;

}
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
 }
cite.MEC36{
color: #ffffff;
}
/* Seperator:37 */
.MES37 {
padding: 30px 0;

& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-10635);}
& > hr {border-top-style: solid;}
 }
/* Content Panel Dark:38 */
.MES38 {
background-color: var(--clr-10625);
color: #ffffff;
padding: 30px 0;

@media #{$large-up} {
padding: 70px 0;

}
 }
.MES38 {
background-color: var(--clr-10625);
color: #ffffff;
padding: 30px 0;

@media #{$large-up} {
padding: 70px 0;

}
h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
 }
cite.MEC38{
color: #ffffff;
}
/* Content Panel White:39 */
.MES39 {
background-color: #ffffff;
color: var(--clr-10625);
padding: 30px 0 60px 0;

@media #{$large-up} {
padding: 70px 0 100px 0;

}
 }
.MES39 {
background-color: #ffffff;
color: var(--clr-10625);
padding: 30px 0 60px 0;

@media #{$large-up} {
padding: 70px 0 100px 0;

}
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: var(--clr-10625);
 }
 }
cite.MEC39{
color: var(--clr-10625);
}
/* FAQ:41 */
details.MES41 {
& > summary{padding: 5px 0;}

& > article{padding: 10px;}

 }
/* Popup Menu:42 */
nav.responsive-menu {
.menu-item.MEC42{background-color: transparent;}
& .menu-item.MEC42, & .menu-item.MEC42 > div.menu-item-wrap{ & > a.MEC42, & > i{color: #ffffff;
text-transform: capitalize;
text-align: center;
}
  }
& .menu-item.MEC42 { border:0;
border-color: transparent;
 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 10px;}

& .sub-menu{.menu-item.MEC42{background-color: 1;}
& .menu-item.MEC42, & .menu-item.MEC42 > div.menu-item-wrap{ & > a.MEC42, & > i{color: 8;
text-transform: capitalize;
text-align: left;
}
  }
 .sub-menu {}}

 }
/* Blog Grid:43 */
.MES43 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-10634);}
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-10625);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES43 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-10634);}
padding: 15px;

@media #{$large-up} {
padding: 50px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-10625);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Blog list:44 */
.MES44 {
background-color: var(--clr-10634);
&:hover, &.hover { background-color: var(--clr-10635);}
color: var(--clr-10625);
 }
.MES44 {
background-color: var(--clr-10634);
&:hover, &.hover { background-color: var(--clr-10635);}
color: var(--clr-10625);
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: var(--clr-10625);
 }
 }
cite.MEC44{
color: var(--clr-10625);
}
/* roll over 3:45 */
.MES45 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-10633);}
color: #ffffff;
 }
.MES45 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-10633);}
color: #ffffff;
h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: transparent;
 }
&:hover { h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: #ffffff;} }
 }
cite.MEC45{
color: #ffffff;
}
/* Carousel padding 5px:46 */
.MES46 {
padding: 2px;

 }
.MES46 {
padding: 2px;

 }
/* Button suppliers:47 */
.MES47 {
background-color: var(--clr-10627);
&:hover {background-color: var(--clr-10628);}
color: #ffffff;
font-size: 16px;
@media #{$medium-up} {
font-size: 18px;
};
border-radius: 50px;
padding: 10px 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* PDF Brochure Buttons:48 */
.MES48 {
background-color: var(--clr-10635);
&:hover {background-color: var(--clr-10629);}
color: var(--clr-10625);
border-radius: 20px;
 }
/* Bold Text:49 */
.MES49 {
color: var(--clr-10625);
font-size: 19.8px;
@media #{$medium-up} {
font-size: 17.6px;
};
 }
.MES49 {
color: var(--clr-10625);
font-size: 19.8px;
@media #{$medium-up} {
font-size: 17.6px;
};
 }
cite.MEC49{
color: var(--clr-10625);
font-size: 19.8px;
@media #{$medium-up} {
font-size: 17.6px;
};
}
/* Black Text:50 */
.MES50 {
color: var(--clr-10625);
 }
.MES50 {
color: var(--clr-10625);
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: var(--clr-10625);
 }
 }
cite.MEC50{
color: var(--clr-10625);
}
/* Tint:51 */
.MES51 {
background-color: var(--clr-10631);
color: #ffffff;
 }
.MES51 {
background-color: var(--clr-10631);
color: #ffffff;
h1.MEC51, h2.MEC51, h3.MEC51, h4.MEC51, h5.MEC51, h6.MEC51 { color: #ffffff;
 }
 }
a.MEC51 { color: #ffffff;
 }
.MEC51 li {color: #ffffff;}
cite.MEC51{
color: #ffffff;
}
/* Parallax:52 */
.MES52 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/23983/47');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 160%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:450px;
@media #{$medium-up} {
min-height: 450px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES52 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://urbansceneconstruction.com.au/img/23983/47');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 160%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:450px;
@media #{$medium-up} {
min-height: 450px;};
@media #{$large-up} {
min-height: 500px;};
 }
/* Hover tinted appear:53 */
.MES53 {
&:hover, &.hover { background-color: var(--clr-10636);}
 }
.MES53 {
&:hover, &.hover { background-color: var(--clr-10636);}
h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: transparent;
 }
&:hover { h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: #ffffff;} }
 }
/* Header Scrolled:54 */
.MES54 {
background-color: var(--clr-10631);
color: #ffffff;
 }
.MES54 {
background-color: var(--clr-10631);
color: #ffffff;
h1.MEC54, h2.MEC54, h3.MEC54, h4.MEC54, h5.MEC54, h6.MEC54 { color: #ffffff;
 }
 }
a.MEC54 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC54{
color: #ffffff;
}
